import { useCallback, useMemo, useState } from 'react';
import { PromptType } from '@remento/types/project';

import { RMConfirmationModal } from '@/components/RMConfirmationModal';
import { useServices } from '@/Services';
import { usePromptTemplatesQuery } from '@/services/cms/prompt-template/prompt-template.service.hook';
import { PromptTemplate } from '@/services/cms/prompt-template/prompt-template.types';
import { PromptTemplateTag } from '@/services/cms/prompt-template-tags/prompt-template-tags.types';
import { secureUuid } from '@/utils/uuid';

import { PromptTemplateList } from '../components/PromptTemplateList';
import {
  addPromptDraft,
  PromptDraftsStore,
  removePromptDraftByTemplateId,
  useIsPromptDraftEdited,
  useIsPromptTemplateSelected,
  usePromptDraftByTemplateId,
} from '../states/prompt-drafts.state';

interface PromptTemplateListContainerProps {
  featured?: boolean;
  tag?: PromptTemplateTag;
  promptDraftsStore: PromptDraftsStore;
}

export function PromptTemplateListContainer({ featured, tag, promptDraftsStore }: PromptTemplateListContainerProps) {
  const { data } = usePromptTemplatesQuery(tag?.value);
  const prompts = useMemo(() => {
    if (!data) {
      return [];
    }

    return featured ? data.slice(0, 3) : data;
  }, [data, featured]);

  return (
    <PromptTemplateList.Root>
      {prompts.map((prompt) => (
        <PromptTemplateListItemContainer
          key={prompt.id}
          prompt={prompt}
          trending={featured ?? false}
          promptDraftsStore={promptDraftsStore}
        />
      ))}
    </PromptTemplateList.Root>
  );
}

export interface PromptTemplateListItemContainerProps {
  prompt: PromptTemplate;
  trending: boolean;
  promptDraftsStore: PromptDraftsStore;
}

export function PromptTemplateListItemContainer({
  prompt,
  trending,
  promptDraftsStore,
}: PromptTemplateListItemContainerProps) {
  const { promptAnalyticsService } = useServices();

  const isSelected = useIsPromptTemplateSelected(promptDraftsStore, prompt.id);
  const isEdited = useIsPromptDraftEdited(promptDraftsStore, prompt.id);
  const selectedPrompt = usePromptDraftByTemplateId(promptDraftsStore, prompt.id);
  const [deselectCustomPromptModalOpen, setDeselectCustomPromptModalOpen] = useState(false);

  const question = useMemo(() => {
    if (selectedPrompt && isEdited) {
      return selectedPrompt.question;
    }

    return prompt.questions[0].text;
  }, [prompt, selectedPrompt, isEdited]);

  const togglePromptSelect = useCallback(async () => {
    if (isSelected) {
      removePromptDraftByTemplateId(promptDraftsStore, prompt.id);
    } else {
      addPromptDraft(promptDraftsStore, {
        id: secureUuid(),
        type: PromptType.TEXT,
        edited: false,
        question: prompt.questions[0].text,
        template: {
          id: prompt.id,
          tagIds: [prompt.tags[0].id],
        },
      });
      promptAnalyticsService.onPromptBankQuestionSelected({
        templateId: prompt.id,
        templateLabel: prompt.title,
        trending,
      });
    }
  }, [isSelected, prompt, promptAnalyticsService, promptDraftsStore, trending]);

  const handlePromptSelect = useCallback(() => {
    if (isSelected && isEdited) {
      setDeselectCustomPromptModalOpen(true);
      return;
    }

    togglePromptSelect();
  }, [isSelected, isEdited, togglePromptSelect]);

  const handleDiscard = useCallback(() => {
    setDeselectCustomPromptModalOpen(false);
    togglePromptSelect();
  }, [togglePromptSelect]);

  return (
    <>
      <PromptTemplateList.Item
        id={prompt.id}
        question={question}
        isSelected={isSelected}
        onClick={handlePromptSelect}
      />
      <RMConfirmationModal
        title="Discard your customization?"
        message="You will lose your work if you deselect this question"
        confirmLabel="Yes, Discard"
        type="danger"
        open={deselectCustomPromptModalOpen}
        onConfirm={handleDiscard}
        onCancel={() => setDeselectCustomPromptModalOpen(false)}
      />
    </>
  );
}
