import { useMemo } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { faClock, faPaperPlaneTop, faPen } from '@fortawesome/pro-regular-svg-icons';
import { faGripVertical } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProjectStatus, PromptStatus } from '@remento/types/project';
import dayjs from 'dayjs';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMText } from '@/components/RMText/RMText';
import { RMTooltip } from '@/components/RMTooltip/RMTooltip';
import { useElementSize } from '@/hooks/useElementSize';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';

import { PromptListItemProps } from './PromptList.types';
import {
  Container,
  DesktopDragIconContainer,
  Image,
  Left,
  LeftTextContent,
  Right,
  StatusContainer,
} from './PromptListItem.styles';

export function PromptListItem({
  id,
  image,
  title,
  timestamp,
  projectStatus,
  status,
  hideActions = false,
  userIsRecipient,
  timePeriod,
  author,
  onClick,
  onAction,
}: PromptListItemProps) {
  const isMobile = useIsMobileViewport();

  const [leftRef, leftSize] = useElementSize();

  // Sortable DnD
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id,
    disabled: status === PromptStatus.SENT,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  // Transform data
  const dayjsTimestamp = useMemo(() => {
    // If it's sent, it may show a date in the future because
    // of time differences on the server/client or delay running the mutation
    const now = Date.now();
    return dayjs(status === PromptStatus.SENT ? Math.min(timestamp, now) : timestamp);
  }, [status, timestamp]);

  const timeString = useMemo(() => {
    const fromNow = dayjsTimestamp.fromNow();
    if (timePeriod === 'PRESENT') {
      return fromNow;
    }
    if (status === PromptStatus.PENDING) {
      return `Sending ${fromNow}`;
    }
    return `Sent ${fromNow}`;
  }, [dayjsTimestamp, timePeriod, status]);

  const timeTooltip = useMemo(() => {
    const formattedTime = dayjsTimestamp.format('MM/DD/YYYY');
    return formattedTime;
  }, [dayjsTimestamp]);

  const messageIcon = useMemo(() => {
    if (timePeriod === 'PRESENT') {
      return faPen;
    } else if (status === PromptStatus.PENDING) {
      return faClock;
    }

    return faPaperPlaneTop;
  }, [status, timePeriod]);

  return (
    <Container
      onClick={() => {
        onClick(id);
      }}
      // DnD
      data-dragging={isDragging}
      style={style}
      ref={setNodeRef}
      {...(isMobile === false ? attributes : null)}
      {...(isMobile === false ? listeners : null)}
    >
      {status === PromptStatus.PENDING && isMobile === false && (
        <DesktopDragIconContainer>
          <FontAwesomeIcon icon={faGripVertical} size="sm" color="var(--primary)" />
        </DesktopDragIconContainer>
      )}
      <Left ref={leftRef}>
        <Image src={image} alt="Prompt image" variant="light" />
        <LeftTextContent>
          <RMText type="sans" size={isMobile ? 's' : 'm'} color="on-surface-primary">
            {title}
          </RMText>

          {(projectStatus !== ProjectStatus.PAUSED || status === PromptStatus.SENT || timePeriod === 'PRESENT') && (
            <RMTooltip message={timeTooltip} side="bottom" align="center">
              <StatusContainer>
                <FontAwesomeIcon icon={messageIcon} size="sm" />
                <RMText type="sans" size="xxs" bold>
                  {timeString} {timePeriod === 'PRESENT' && <>· {author}</>}
                </RMText>
              </StatusContainer>
            </RMTooltip>
          )}
        </LeftTextContent>
      </Left>

      <Right
        onClick={(event) => event.stopPropagation()}
        style={{ height: leftSize.height }}
        data-show-only-on-hover={status === PromptStatus.PENDING && isMobile === false}
        {...(isMobile === true ? attributes : null)}
        {...(isMobile === true ? listeners : null)}
      >
        {status === PromptStatus.PENDING && isMobile === true && (
          <FontAwesomeIcon className="drag-icon" icon={faGripVertical} size="sm" color="var(--primary)" />
        )}
        {hideActions === false &&
          status === PromptStatus.PENDING &&
          !userIsRecipient &&
          projectStatus === ProjectStatus.ACTIVE &&
          isMobile === false && (
            <RMButton size="small" background="outlined" onClick={() => onAction(id, 'SEND-NOW')}>
              Send now
            </RMButton>
          )}
        {hideActions === false &&
          (status === PromptStatus.SENT || (status === PromptStatus.PENDING && userIsRecipient)) &&
          projectStatus === ProjectStatus.ACTIVE &&
          isMobile === false && (
            <RMButton size="small" background="outlined" onClick={() => onAction(id, 'RECORD')}>
              Record
            </RMButton>
          )}
      </Right>
    </Container>
  );
}
