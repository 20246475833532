import { useCallback, useRef } from 'react';
import lottie, { AnimationItem } from 'lottie-web';

import {
  AnimationWrapper,
  CardBottom,
  CardText,
  CardTitle,
  CardTop,
  CardWrapper,
  Image,
  LottieWrapper,
} from './AddPromptsSelectTypeCard.styles';

interface AddPromptsSelectTypeCardProps {
  id?: string;
  title: string;
  text: string;
  lottieSrc?: any;
  imageSrc?: string;
  onClick?: () => void;
}

export function AddPromptsSelectTypeCard({
  id,
  title,
  text,
  lottieSrc,
  imageSrc,
  onClick,
}: AddPromptsSelectTypeCardProps) {
  const animationRef = useRef<AnimationItem | null>(null);
  const lottieRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (node === null) {
        if (animationRef.current) {
          animationRef.current.destroy();
          animationRef.current = null;
        }
        return;
      }

      const animation = lottie.loadAnimation({
        container: node,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData: lottieSrc,
      });
      animationRef.current = animation;
    },
    [lottieSrc],
  );

  const handleHover = (hovering: boolean) => {
    if (hovering) {
      animationRef.current?.setDirection(1);
    } else {
      animationRef.current?.setDirection(-1);
    }
    animationRef.current?.play();
  };

  return (
    <CardWrapper
      id={id}
      onClick={onClick}
      onMouseEnter={() => handleHover(true)}
      onMouseLeave={() => handleHover(false)}
    >
      <CardTop>
        {lottieSrc && (
          <AnimationWrapper>
            <LottieWrapper ref={lottieRef} />
          </AnimationWrapper>
        )}
        {imageSrc && (
          <AnimationWrapper>
            <Image src={imageSrc} />
          </AnimationWrapper>
        )}
      </CardTop>
      <CardBottom>
        <CardTitle>{title}</CardTitle>
        <CardText>{text}</CardText>
      </CardBottom>
    </CardWrapper>
  );
}
