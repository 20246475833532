import { RMCheckbox } from '@/components/RMCheckbox/RMCheckbox';
import { RMText } from '@/components/RMText/RMText';
import { isMobileViewport } from '@/hooks/useIsMobileViewport';

import { Item } from './PromptTemplateListItem.styles';

interface PromptTemplateListItemProps {
  id: string;
  question: string;
  isSelected: boolean;
  onClick: (id: string) => void;
}

export function PromptTemplateListItem({ id, question, isSelected, onClick }: PromptTemplateListItemProps) {
  const mobile = isMobileViewport();

  return (
    <Item data-selected={isSelected} data-mobile={mobile} onClick={() => onClick(id)}>
      <RMCheckbox checked={isSelected} square={true} onChange={() => null} />

      <RMText type="sans" size={mobile ? 's' : 'm'} color="on-surface-primary">
        {question}
      </RMText>
    </Item>
  );
}
