import PromptsEmptySrc from '@/assets/prompts-empty.svg';
import { RMText } from '@/components/RMText/RMText';

import { Container, Image } from './PromptListEmpty.styles';

export interface PromptListEmptyProps {
  hasTopics: boolean;
}

export function PromptListEmpty({ hasTopics }: PromptListEmptyProps) {
  return (
    <Container>
      <Image src={PromptsEmptySrc} alt="Prompt ghost icon" />
      <RMText align="center" type="sans" size="s" color="on-surface-tertiary">
        {hasTopics ? 'Prompts' : 'Photos'} will
        <br />
        appear here.
      </RMText>
    </Container>
  );
}
