import { styled } from '@linaria/react';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  text-align: center;
  height: 100%;
  width: 100%;
  gap: var(--spacing-lg);
`;

export const Image = styled.img`
  width: 196px;
  height: 196px;
`;
