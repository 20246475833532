import { ReactNode } from 'react';

import { List } from './PromptTemplateListRoot.styles';

type PromptTemplateListRootProps = {
  children: ReactNode;
};

export function PromptTemplateListRoot({ children }: PromptTemplateListRootProps) {
  return <List>{children}</List>;
}
