import { ComponentRef, forwardRef, PropsWithChildren, useCallback, useImperativeHandle, useMemo, useRef } from 'react';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  PointerSensor,
  SensorDescriptor,
  SensorOptions,
  TouchSensor,
} from '@dnd-kit/core';
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';

import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';

import { List } from './PromptListRoot.styles';

export type PromptListProps = PropsWithChildren<{
  items: string[];
  onMove: (draggedId: string, droppedId: string) => void;
}>;

export interface PromptListRootRef {
  scrollToBottom: () => void;
}

export const PromptListRoot = forwardRef<PromptListRootRef, PromptListProps>(({ items, onMove, children }, ref) => {
  const bottomRef = useRef<ComponentRef<'div'>>(null);

  const isMobile = useIsMobileViewport();
  const sensors = useMemo<SensorDescriptor<SensorOptions>[]>(() => {
    const options = {
      activationConstraint: {
        distance: 4,
      },
    };

    const isTouchscreen = window.matchMedia('(pointer: coarse)').matches;
    // PointerSensor also works with touch screens devices, but for small and touch devices
    // the  TouchSensor works way better.
    return [{ sensor: isMobile && isTouchscreen ? TouchSensor : PointerSensor, options }];
  }, [isMobile]);

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (over && active.id !== over.id) {
        onMove(active.id as string, over.id as string);
      }
    },
    [onMove],
  );

  useImperativeHandle(ref, () => ({
    scrollToBottom: () => {
      bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    },
  }));

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        <List>{children}</List>
        <div ref={bottomRef} />
      </SortableContext>
    </DndContext>
  );
});

PromptListRoot.displayName = 'PromptListRoot';
