import { styled } from '@linaria/react';

export const Item = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: var(--spacing-md);
  gap: var(--spacing-md);
  border-bottom: 1px solid var(--border-hairline);

  &[data-mobile='true'] {
    padding: var(--spacing-md) var(--spacing-lg);
  }

  &:after {
    position: absolute;
    content: ' ';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  :hover {
    &:after {
      background-color: var(--darken-neutral-hover);
    }
  }

  :active,
  &[data-selected='true'] {
    &:after {
      background-color: var(--darken-neutral-press);
    }
  }
`;
