import { PromptListEmpty } from './PromptListEmpty';
import { PromptListItem } from './PromptListItem';
import { PromptListRoot } from './PromptListRoot';

export type { PromptListItemAction } from './PromptList.types';

export const PromptList = {
  Root: PromptListRoot,
  Item: PromptListItem,
  Empty: PromptListEmpty,
};
