import { styled } from '@linaria/react';

export const List = styled.div`
  display: flex;
  flex-flow: column;
  background-color: var(--surface-bright);
  width: 100%;

  body[data-mobile='false'] & {
    border-radius: var(--radius-smooth);
    box-shadow: var(--elevation-1);
  }

  body[data-mobile='true'] & {
    border: 1px solid var(--border-hairline);
    padding: 0 1px;
  }
`;
